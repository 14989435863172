// eslint-disable-next-line
import React, { useState, useEffect } from 'react';
import styles from './Gallery.module.scss';
import yaml from 'js-yaml';
import { useTrail, animated } from 'react-spring';

import Loader from '../Loader/Loader';

const Gallery = ({ forwardRef }) => {
  const [num] = useState('02');
  const [title] = useState('Galerie');
  const [data, setData] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    fetch('artworks.yaml')
      .then((res) => res.text())
      .then((text) => {
        const doc = yaml.load(text, 'utf8');
        const artworks = doc.artworks.sort((a, b) => b.year - a.year);
        setData(artworks);
      })
      .catch((err) => {
        setError(err);
      });
  }, []);

  const trail = useTrail(data?.length, {
    config: { mass: 5, tension: 1000, friction: 200 },
    from: { opacity: 0, transform: 'translateX(20px)' },
    to: { opacity: 1, transform: 'translateX(0px)' },
  });

  const images = trail.map((props, index) => {
    const artwork = data[index];

    return (
      <animated.div className={styles.GalleryCard} key={index} style={props}>
        <h4>
          {artwork.year} &mdash; {artwork.title}
        </h4>
        <div className={styles.ImageContainer}>
          <img
            src={'https://artworks.ritapeters.de/' + artwork.image}
            alt={artwork.title}
          ></img>
        </div>
        <div className={styles.InfoContainer}>
          <p>{artwork.material}</p>
          <p>
            {artwork.width} cm x {artwork.height} cm
          </p>
        </div>
      </animated.div>
    );
  });

  const loading = (d) => {
    return data && data.length > 0 ? (
      <div className={styles.ImgGrid}>{images}</div>
    ) : (
      <Loader />
    );
  };

  return (
    <>
      <div ref={forwardRef} className={styles.Gallery}>
        <h4>
          {num} &mdash; {title}
        </h4>
        <h2>Ausgewählte Werke</h2>
        {error ? (
          <h4 style={{ color: 'red' }}>
            Etwas ist schief gelaufen. Bitte probiere es später nocheinmal.
          </h4>
        ) : (
          loading(images)
        )}
      </div>
    </>
  );
};

export default Gallery;
