// eslint-disable-next-line
import React, { useState } from 'react';
import styles from './Vita.module.scss';

const Vita = ({ forwardRef }) => {
  const [num] = useState('03');
  const [title] = useState('Vita');

  return (
    <div ref={forwardRef} className={styles.Vita}>
      <h4>
        {num} &mdash; {title}
      </h4>
      <h2>Meine Geschichte</h2>
      {/* <div className={styles.OuterContainer}> */}
      <div className={styles.Container}>
        <div className={styles.PictureContainer}>
          <div className={styles.Picture}>
            <img
              src="https://artworks.ritapeters.de/20201026_rita.jpg"
              alt="img"
            />
          </div>
        </div>
        <div className={styles.Para}>
          <h4>Rita Peters</h4>
          <div className={styles.Text}>
            <p>
              ... wurde 1955 in Buchholz in der Nordheide geboren. Heute lebt
              sie mit ihrer Familie in Hanstedt-Weihe. Ihre Werke entstehen im
              hauseigenen Atelier und auch im Dialog mit anderen Künstlern in
              offenen Ateliers. Sie begann ihre künstlerische Tätigkeit 1998;
              angefangen als Autodidakt hat sie über verschiedene Seminare ihre
              Fähigkeiten stetig weiterentwickelt.
            </p>
          </div>
        </div>
        <div className={styles.Para}>
          <h4>Technik</h4>
          <div className={styles.Text}>
            <p>
              Ihre Acrylbilder entstehen in Mal- und Spachteltechnik, auch auf
              unterschiedlichen Strukturen und zeigen bevorzugt Menschen und
              deren Umfeld. Die Details werden verfremdet, bleiben aber für den
              Betrachter erkennbar. Zufälligkeiten, die entstehen, lassen Raum
              für eigene Interpretationen.
            </p>
          </div>
        </div>
      </div>
      {/* </div> */}
    </div>
  );
};

export default Vita;
