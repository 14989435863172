// eslint-disable-next-line
import React from 'react';
import styles from './Footer.module.scss';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className={styles.Footer}>
      <div className={styles.Info}>
        <h4>
          <Link to="/impressum">&#8594; Impressum</Link>
        </h4>
        <h4>&#169; Alle Rechte vorbehalten.</h4>
      </div>
      <h4 className={styles.Up}>
        <a href="#top">&#8594;</a>
      </h4>
    </div>
  );
};

export default Footer;
