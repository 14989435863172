// eslint-disable-next-line
import React from 'react';
import styles from './Imprint.module.scss';

const Imprint = ({ forwardRef }) => {
  return (
    <div ref={forwardRef} className={styles.Imprint}>
      <div className={styles.Text}>
        <h4>Impressum</h4>
        <div className={styles.Paragraph}>
          <p>Angaben gemäß § 5 TMG</p>
        </div>
        <div className={styles.Paragraph}>
          <p>Rita Peters</p>
          <p>Seevestraße 19</p>
          <p>21271 Hanstedt</p>
        </div>
        <div className={styles.Paragraph}>
          <h4>Kontakt</h4>
          <p>Telefon: +49 4183 503 41</p>
          <p>
            E-Mail: <a href="mailto:kunst@ritapeters.de">kunst@ritapeters.de</a>
          </p>
        </div>
      </div>
      <div className={styles.Links}>
        <h4>Gestaltung & Umsetzung</h4>
        <p>Nino Peters</p>
        <p>
          E-Mail: <a href="mailto:dev@ninopeters.de">dev@ninopeters.de</a>
        </p>
      </div>
    </div>
  );
};

export default Imprint;
