// eslint-disable-next-line
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './Navigation.module.scss';

const Navigation = ({ forwardRef }) => {
  return (
    <div ref={forwardRef} className={styles.Navigation}>
      <h4>05 - Navigation</h4>
      <div className={styles.Links}>
        <div className={styles.Menu}>
          <h2>Menü</h2>
          <Link to="/">
            <h4>01 &mdash; Start</h4>
          </Link>
          <Link to="/galerie">
            <h4>02 &mdash; Galerie</h4>
          </Link>
          <Link to="/vita">
            <h4>03 &mdash; Vita</h4>
          </Link>
          <Link to="/ausstellungen">
            <h4>04 &mdash; Ausstellungen</h4>
          </Link>
        </div>
        <div className={styles.External}>
          <h2>Externe Links</h2>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/peters.rita/"
          >
            <h4>Instagram Profil</h4>
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.facebook.com/ritapeters.de/"
          >
            <h4>Facebook Seite</h4>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Navigation;
