// eslint-disable-next-line
import React from 'react';
import { useSpring, animated } from 'react-spring';
import styles from './Header.module.scss';
import { Link } from 'react-router-dom';

const Header = () => {
  const titleProps = useSpring({
    transform: 'translate(0, 0)',
    opacity: 1,
    from: { transform: 'translate(-2rem, 0)', opacity: 0 },
  });

  const menuProps = useSpring({
    transform: 'translate(0, 0)',
    opacity: 1,
    from: { transform: 'translate(2rem, 0)', opacity: 0 },
  });

  return (
    <div className={styles.Header}>
      <animated.div style={titleProps} className={styles.Title}>
        <Link to="/">
          <h4>Rita Peters</h4>
          <h4>Vielschichtig</h4>
        </Link>
      </animated.div>
      <animated.div className={styles.HeaderNav} style={menuProps}>
        <h4>
          <Link to="/galerie">&#8594; Galerie</Link>
        </h4>
        <h4>
          <Link to="/menu">&#8594; Mehr</Link>
        </h4>
      </animated.div>
    </div>
  );
};

export default Header;
