// eslint-disable-next-line
import React, { useState } from 'react';
import styles from './Welcome.module.scss';

const Welcome = ({ forwardRef }) => {
  const [num] = useState('01');
  const [title] = useState('Start');

  return (
    <div className={styles.WelcomeContainer}>
      <div ref={forwardRef} className={styles.Welcome}>
        <h4>
          {num} &mdash; {title}
        </h4>
        <h2>Herzlich Willkommen</h2>
        <div className={styles.Message}>
          <div className={styles.Citat}>
            <p>
              "Kunst wird erst dann interessant, wenn wir vor irgend etwas
              stehen, das wir nicht gleich restlos erklären können."
            </p>
            <div className={styles.Author}>
              <h4>Christoph Schlingensief</h4>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
