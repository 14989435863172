// eslint-disable-next-line
import React, { useEffect, useState } from 'react';
import { useTransition, animated } from 'react-spring';
import useMeasure from 'react-use-measure';
import styles from './App.module.scss';
import { Switch, Route, useLocation } from 'react-router-dom';

import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import Welcome from './components/Welcome/Welcome';
import Gallery from './components/Gallery/Gallery';
import Vita from './components/Vita/Vita';
import Exhibitions from './components/Exhibitions/Exhibitions';
import Navigation from './components/Navigation/Navigation';
import Imprint from './components/Imprint/Imprint';

function App() {
  const [contentRef, contentBounds] = useMeasure();
  const [contentHeight, setContentHeight] = useState(0);
  const location = useLocation();
  const [background, setBackground] = useState(
    'linear-gradient(-45deg, #ffe8bd 0%, #fff8e2 50%)'
  );

  useEffect(() => {
    setContentHeight(contentBounds.height);

    if (location.pathname === '/') {
      setBackground(
        'url("https://artworks.ritapeters.de/gesellschaft_2_2020.jpeg")'
      );
    } else {
      setBackground('linear-gradient(-45deg, #ffe8bd 0%, #fff8e2 50%)');
    }
  }, [contentBounds.height, location.pathname]);

  const transitions = useTransition(location, (location) => location.pathname, {
    from: { opacity: 0, transform: 'translateX(15px)' },
    enter: { opacity: 1, transform: 'translateX(0px)' },
    leave: { opacity: 0, transform: 'translateX(-15px)' },
  });

  const anmiatedSwitch = transitions.map(({ item: location, props, key }) => (
    <animated.div key={key} style={props}>
      <Switch location={location}>
        <Route
          path="/galerie"
          render={(props) => <Gallery {...props} forwardRef={contentRef} />}
        />
        <Route
          path="/vita"
          render={(props) => <Vita {...props} forwardRef={contentRef} />}
        />
        <Route
          path="/ausstellungen"
          render={(props) => <Exhibitions {...props} forwardRef={contentRef} />}
        />
        <Route
          path="/"
          render={(props) => <Welcome {...props} forwardRef={contentRef} />}
          exact
        />
        <Route
          path="/menu"
          render={(props) => <Navigation {...props} forwardRef={contentRef} />}
        />
        <Route
          path="/impressum"
          render={(props) => <Imprint {...props} forwardRef={contentRef} />}
        />
      </Switch>
    </animated.div>
  ));

  return (
    <div className={styles.App} id="top">
      <Header />
      <div
        style={{ minHeight: contentHeight, backgroundImage: background }}
        className={styles.Content}
        id="content"
      >
        {anmiatedSwitch}
      </div>
      <Footer />
    </div>
  );
}

export default App;
