import React from 'react';
import styles from './Loader.module.scss';

const Loader = () => {
  return (
    <div className={styles.Loader}>
      <svg viewBox="0 0 400 400">
        <g fill="#3e3e3e" fillRule="evenodd">
          <path id="path-1" d="M20,380 L380,380 L380,380 L200,20 L20,380 Z" />
        </g>
      </svg>
      <svg viewBox="0 0 400 400">
        <g fill="#3e3e3e" fillRule="evenodd">
          <path id="path-1" d="M20,380 L380,380 L380,380 L200,20 L20,380 Z" />
        </g>
      </svg>
      <svg viewBox="0 0 400 400">
        <g fill="#3e3e3e" fillRule="evenodd">
          <path id="path-1" d="M20,380 L380,380 L380,380 L200,20 L20,380 Z" />
        </g>
      </svg>
    </div>
  );
};

export default Loader;
