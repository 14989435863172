import React, { useState, useEffect } from 'react';
import { useTrail, animated } from 'react-spring';
import styles from './Exhibitions.module.scss';
import Loader from '../Loader/Loader';
import yaml from 'js-yaml';


const Exhibitions = ({ forwardRef }) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    fetch('exhibitions.yaml')
      .then(res => res.text())
      .then(text => {
        const doc = yaml.load(text, 'utf8')
        const exhibitions = doc.exhibitions.sort((a, b) => b.year - a.year);
        setData(exhibitions);
      })
      .catch(err => {
        setError(err)
      });
  }, []);

  const trail = useTrail(data?.length, {
    config: { mass: 5, tension: 2000, friction: 200 },
    from: { opacity: 0, transform: 'translateX(20px)' },
    to: { opacity: 1, transform: 'translateX(0px)' },
  });

  const exhibs = trail.map((props, index) => {
    return (
      <span key={index}>
        {data &&
          <animated.div className={styles.Row} style={props}>
            <h4>
              {data[index].year} &mdash; {data[index].title}
            </h4>
          </animated.div>
        }
      </span>
    );
  });

  const loading = (d) => {
    return (
      data && data.length > 0 ? (
        <div>{d}</div>
      ) : (
        <Loader />
    ))
  }

  return (
    <div ref={forwardRef} className={styles.Exhibitions}>
      <h4>04 &mdash; Austellungen</h4>
      <h2>Alle Veranstaltungen</h2>
      {error ? (
        <h4 style={{ color: 'red' }}>Etwas ist schief gelaufen. Bitte probiere es später nocheinmal.</h4>
      ) : loading(exhibs)}
    </div>
  );
};

export default Exhibitions;
